import type { OrganizationUserRole } from "docuchatcommontypes";
import type { DashboardSidebarLink } from "#ui-pro/types";

export interface LinkWithPermission extends DashboardSidebarLink {
  accessibleTo: OrganizationUserRole[];
  accessibleWhen?: () => boolean;
  children?: LinkWithPermission[];
}

export enum UserAction {
  CreateChatbot = "CreateChatbot",
  ChatWithChatbot = "ChatWithChatbot",
  ShareChatbot = "ShareChatbot",
  EditChatbot = "EditChatbot",
  DeleteChatbot = "DeleteChatbot",
  AddDocument = "AddDocument",
  OpenDocument = "OpenDocument",
  RenameDocument = "RenameDocument",
  DeleteDocument = "DeleteDocument",
  DeleteAccount = "DeleteAccount",
  OpenBillingPortal = "OpenBillingPortal",
}

export type ActionPermissions = {
  [K in UserAction]: OrganizationUserRole[];
};
